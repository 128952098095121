





































import { BFormDatepicker, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend } from "bootstrap-vue";
import Cleave from 'vue-cleave-component'
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    BFormDatepicker,
    BInputGroup, 
    BInputGroupAppend, 
    BInputGroupPrepend, 
    BFormInput,
    Cleave
  },
  name: "ressifnet-date-input",
})
export default class RessifnetDateInput extends Vue {
  @Prop({ required: true }) value: any
  @Prop({ required: false, default: false }) displayAge!: boolean

  valueDate: Date | null = null;
  dateOptions: any = {
    date: true,
    delimiter: '/',
    datePattern: ['d','m','Y'],
  }

  get inputVal() {
    return this.value
  }
  set inputVal(val: any) {
    this.$emit('input', val)

    var dateParts : any[] = val.split("/");
    this.valueDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
  }

  get age() {
    if (this.valueDate) {
      let ageInMilliseconds: any = new Date().getTime() - this.valueDate.getTime()
      let res = Math.floor(ageInMilliseconds/1000/60/60/24/365);
      if (!isNaN(res) && res > 0) return res
      return '-'
    }
    else {
      return '-';
    }
  }
  dateNaissancePrompted(newValue: any) {
    if (newValue instanceof Date) {
      this.inputVal = newValue.toLocaleDateString()
    }
  }
}

