






















































































































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPopover,
  BFormTextarea,
  BTooltip,
  BFormCheckbox,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BForm,
  BAlert,
  BFormGroup,
  VBTooltip,
  BAvatar,
  BAvatarGroup,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import {
  CreateFicheIncidentModel,
  FicheIncidentStatut,
} from "../../api/models/fichesIncident/ficheIncident";
import { FicheIncidentGraviteEnum } from "@/api/models/enums/ficheIncidentGraviteEnum";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { Processus } from "@/api/models/fichesIncident/processus";
import { BSpinner } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Utilisateur } from "@/api/models/utilisateurs/utilisateur";

Vue.directive("b-tooltip", VBTooltip);
@Component({
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BFormCheckbox,
    BAvatar,
    BFormTextarea,
    BAvatarGroup,
    BPopover,
    BSpinner,
    BTooltip,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    RessifnetDateInput,
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
  },
})
export default class ListFichesIncidents extends Vue {
  graviteEnum = FicheIncidentGraviteEnum;
  creating = false;

  typesIncidentOptions = [];
  gravitesOptions: {
    key: FicheIncidentGraviteEnum;
    text: string;
  }[] = [
    { key: FicheIncidentGraviteEnum.FAIBLE, text: "Faible" },
    { key: FicheIncidentGraviteEnum.MOYENNE, text: "Moyenne" },
    { key: FicheIncidentGraviteEnum.HAUTE, text: "Haute" },
  ];
  processusOptions: Processus[] = [];
  statutOptions: FicheIncidentStatut[] = [];
  localisationOptions = [];

  avatarColors: string[] = [
    "#3498db", // Bleu clair
    "#2ecc71", // Vert menthe
    "#e74c3c", // Rouge doux
    "#f39c12", // Orange
    "#9b59b6", // Violet doux
    "#34495e", // Bleu nuit
    "#1abc9c", // Vert d'eau
    "#e67e22", // Orange foncé
    "#95a5a6", // Gris clair
    "#d35400", // Orange brûlé
  ];
  avatarColorsMap: { [key: string]: string } = {};

  newFicheIncident: CreateFicheIncidentModel = {
    titre: null,
    description: null,
    dateIncident: null,
    typeIncident: null,
    processusId: null,
    gravite: null,
    localisation: null,
    eventuellesSuggestions: null,
    traitementImmediat: null,
  };

  listUtilisateursResponsable: Utilisateur[] = [];
  listUtilisateursAssignes: Utilisateur[] = [];

  async created() {
    await this.loadTypesIncidentOptions();
    await this.loadListProcesssus();
    await this.loadLocalisationsOptions();
  }

  async loadListProcesssus(search: string = "") {
    await this.$http.myressif.processus.searchPaginatedList(1, 20, search).then(
      (response: PaginatedList<Processus>) => {
        this.processusOptions = response.items;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des processus",
          text: error.message,
        });
      }
    );
  }

  async loadTypesIncidentOptions() {
    await this.$http.myressif.parametres.getByNom("fi_type_incident").then(
      (res: any) => {
        if (res.valeur) {
          let options: [] = JSON.parse(res.valeur);
          this.typesIncidentOptions.push(...options);
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des types d'incident",
          text: error.message,
        });
      }
    );
  }

  async loadLocalisationsOptions() {
    await this.$http.myressif.parametres.getByNom("fi_localisation").then(
      (res: any) => {
        if (res.valeur) {
          let options: [] = JSON.parse(res.valeur);
          this.localisationOptions.push(...options);
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des localisations",
          text: error.message,
        });
      }
    );
  }

  async submitFiche() {
    this.creating = true;
    await this.$http.myressif.fichesIncident.post(this.newFicheIncident).then(
      async (response: any) => {
        successAlert.fire({
          title: "Création d'une fiche incident",
          text: "Création effectuée avec succès",
        });
        this.newFicheIncident = {
          titre: null,
          description: null,
          dateIncident: null,
          typeIncident: null,
          processusId: null,
          gravite: null,
          localisation: null,
          eventuellesSuggestions: null,
          traitementImmediat: null,
        };
      },
      (error: any) => {
        errorAlert.fire({
          title: "Oops",
          text: error.message,
        });
      }
    );
    this.creating = false;
  }
}
